import { useState } from 'react';
import { Form, message } from '@/components/antd';
import { getTranslatedString } from '@/utils';
import { showError } from '@/utils/common';

export const useDrawerForm = <T = any,>(onChange?: (changedFields: T) => void) => {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [formRef] = Form.useForm();
  const form = formRef;

  const onStartSubmit = () => {
    setLoadingSubmit(true);
  };
  const cancelSubmit = () => {
    setLoadingSubmit(false);
    setDisableSubmit(false);
  };

  const onFinishSubmit = () => {
    setLoadingSubmit(false);
  };
  const onFieldsChange = (changedFields: any, all: any[]) => {
    if (onChange) {
      onChange(changedFields);
    }
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (disableSubmit !== disable) {
      setDisableSubmit(disable);
    }
  };
  return {
    loadingSubmit,
    disableSubmit,
    form,
    onStartSubmit,
    onFinishSubmit,
    onFieldsChange,
    cancelSubmit,
  };
};

export const useModal = ({ cancelSubmit, update, formValues, foundItem, store, onClose }) => {
  const [open, setOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const handleCancel = () => {
    cancelSubmit();
    setOpen(false);
  };
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    const { weekDays, ...valuesWithoutWeekDays } = formValues;
    try {
      setModalLoading(true);
      if (formValues.scheduleType === 'calendar') {
        await update(foundItem.id, { weekDays: [], ...valuesWithoutWeekDays }).source.then(() => store.refresh());
      } else {
        await update(foundItem.id, formValues).source.then(() => store.refresh());
      }
      onClose();
      message.success(getTranslatedString('colors.notificationSuccess'));
    } catch (e) {
      showError(e);
    } finally {
      handleCancel();
      setModalLoading(true);
    }
  };
  return { handleCancel, showModal, handleOk, open, modalLoading };
};
